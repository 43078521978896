import request from '../utils/request'
import config from './config'

/**
 * 用户管理
 */
// 部门-tree
export function departmentTreeList(params) {
  return request({
    url: `${config.CIVIL_HOST}/departmentManage/queryLowerDepartment`,
    method: 'get',
    params,
  })
}

// 用户新增、编辑
export function userAddOrEdit(data) {
  return request({
    url: `${config.CIVIL_HOST}/civilCount/addOrUpdateCivilAffairsBureauAccount`,
    method: 'post',
    data,
  })
}

//用户列表
export function getUserList(data) {
  return request({
    url: `${config.CIVIL_HOST}/civilCount/queryCivilAffairsBureauAccountList`,
    method: 'post',
    data,
  })
}

//停用、启用账号
export function changeAccountStatus(params) {
  return request({
    url: `${config.CIVIL_HOST}/civilCount/ChangeCivilAffairsBureauAccountStatus`,
    method: 'get',
    params
  })
}

//删除账号
export function delUser(params) {
  return request({
    url: `${config.CIVIL_HOST}/civilCount/deleteCivilAffairsBureauAccount`,
    method: 'get',
    params
  })
}

//重置密码
export function resetPassword(data) {
  return request({
    url: `${config.CIVIL_HOST}/civilCount/resetPassword`,
    method: 'post',
    data
  })
}


//用户名去重
export function usernameDeduplication(params) {
  return request({
    url: `${config.CIVIL_HOST}/civilCount/usernameDeduplication`,
    method: 'get',
    params
  })
}

//角色列表
export function selectRole(params) {
  return request({
    url: `${config.CIVIL_HOST}/civilAffairsBureauRole/selectRole`,
    method: 'get',
    params
  })
}


